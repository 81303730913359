import React from "react";
import styled from "styled-components";
import { About } from "../styles";
import Toggle from "./Toggle";
import { AnimateSharedLayout } from "framer-motion";
import { useScroll } from "./useScroll";
import { scrollReveal} from "../animation";
import { motion } from "framer-motion";
import {  fade } from "../animation";
//Images
import html from "../img/html.png";
import css from "../img/css.png";
import js from "../img/js.png";
import php from "../img/php.png";
import mysql from "../img/mysql.png";
import wordpress from "../img/wordpress.png";
import react from "../img/react.png";


const SkillsSection = () => {
  const [element, controls] = useScroll();
  return (
    <Skills
      variants={scrollReveal}
      ref={element}
      animate={controls}
      initial="hidden"
    >
      <h2>&#60;skills&#62;</h2>
      <AnimateSharedLayout>
        <Toggle title="HTML">
          <div className="answer">
          <img alt="html" src={html} />
            <p>
            &#60;&#62;<br></br>
            HTML I use to include in web pages, video, audio, and even entire other webpages
             registering and logging in, sending feedback and more: dealing with titles,
             adding images or videos, emphasizing content, creating a basic form, etc.
             <br></br> &#60; &#47; &#62;
            </p>
          
          </div>
        </Toggle>
        <Toggle title="CSS">
          <div className="answer">
          <img alt="css" src={css} />
            <p>
            &#123;<br></br>
            CSS style and layout web pages for example, how to style text, and how to style and manipulate the boxes that your content sits inside. Different display settings,
             modern layout tools like flexbox, CSS grid, and positioning, animations, and others.
             <br></br>&#125;&#59;
            </p>
          </div>
        </Toggle>
        <Toggle title="Javascript">
          <div className="answer">
          <img alt="js" src={js} />
            <p>
            &#61; &#62; &#123;<br></br>
            Javascript to adds interactivity to your website
            with data entry on forms, dynamic styling, with animation your websites
             will enter a new dimension of power and creativity.
            JavaScript itself is a relatively compact, yet very flexible language.
            <br></br>&#125;&#59;
            </p>
          </div>
        </Toggle>
        <Toggle title="PHP ">
          <div className="answer">
            <img alt="php" src={php} />
            <p>
            &#60;&#63;<br></br>
            PHP I start to add an open-source server-side scripting 
            language that can be embedded into HTML
             to build web applications and dynamic websites.
             <br></br>&#63;&#62;
            </p>
          </div>
        </Toggle>
        <Toggle title="MYSQL">
          <div className="answer">
            <img alt="mysql" src={mysql} />
            <p>
            &#64;&#64;UNIQUE_CHECKS<br></br>
            MYSQL I use to handling and querying databases...
            <br></br>&#125;&#59;
            </p>
          </div>
        </Toggle>
        <Toggle title="WORDPRESS">
          <div className="answer">
            <img alt="wordpress" src={wordpress} />
            <p>
            &#60;&#63;<br></br>
            When the website is ready for build I taking the graphical elements (colors, fonts,
             layout, images) defined in the design
             process and coding them using web industry standards (PHP, HTML5, CSS3, JS, jQuery).
             <br></br>&#63;&#62;
            </p>
          </div>
        </Toggle>
        <Toggle className="title" title="REACT">
          <div className="answer">
            <img alt="react" src={react} />
            <p>
            import &#123;<br></br>
            React takes the description and displays the result.
             In particular, render returns a React element, which is a lightweight description of what to render.
            Most React developers use a special syntax called “JSX” which makes these structures easier to write.
            The ('div')  syntax is transformed at build time to React.createElement('div').
            <br></br>&#125;&#59;<br></br>export
            </p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
      <h2>&#60;&#47;skills&#62;</h2>
    </Skills>
  );
};

const Skills = styled(About)`
  display: block;
  h2 {
    padding-bottom: 5rem;
    margin: 0rem 90rem 0rem 0rem;
    font-weight: lighter;
  }
  .faq-line {
    background: #23d997;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .question {
    padding: 3rem 0rem;
    cursor: pointer;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
    a{  
      color: white;
      text-decoration:none;   
    }
    button{
      margin: 0rem 0.5rem 0.5rem 0rem;
      }
  @media (max-width: 500px) {
  
    h2 {
      font-size: 2.5rem;
      font-weight: lighter;
    }
    p{  
        font-size: 1.2rem;  
      }
      
     
  }
`;


export default SkillsSection;
