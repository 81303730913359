//Import Images
import Baerumhub from "./img/Baerumhub.png";
import Genex from "./img/Genex.png";
import Piks from "./img/Piks.png";
import musicapp from "./img/musicapp.png";
import gameapp from "./img/gameapp.png";
import game from "./img/game.png";
import html1 from "./img/html1.png";
import cube from "./img/cube.png";
import todo from "./img/todo.png";
import Beatmaker from "./img/Beatmaker.png";
import Coloors from "./img/Coloors.png";
import Baerumhub2 from "./img/Baerumhub2.png";
import Genex2 from "./img/Genex2.png";
import Piks2 from "./img/Piks2.png";
import musicapp2 from "./img/musicapp2.png";
import gameapp2 from "./img/gameapp2.png";
import game2 from "./img/game2.png";
import html2 from "./img/html2.png";
import cube2 from "./img/cube2.png";
import todo2 from "./img/todo2.png";
import Beatmaker2 from "./img/Beatmaker2.png";
import Coloors2 from "./img/Coloors2.png";

export const MovieState = () => {
  return [
    {
      title: "Bærum hub...",
      mainImg: Baerumhub,
      secondaryImg: Baerumhub2,
      url: "/work/Baerumhub",
      awards: [
        {
          title: "Services",
          description:
            "“The site is made in Word-press. Modern technologies and possibilities provided by Word-press represent easy and dynamic use of already given plugins and themes.”",
        },
        {
          title: "Web design",
          description:
            "“The design of the website and individual pages was done in agreement with the client. The most important thing is that the client is satisfied, the design itself provides that luxury to the developer.”",
        },
        {
          title: "Clients",
          description:
            "“Some of the offers that Bærum hub offers are programs that will help you to start your own business, office, desk, meeting room. Feel free and see for yourself!!! By visiting the site”",
        },
      ],
    },
    {
      title: "Genex",
      mainImg: Genex,
      url: "/work/Genex",
      secondaryImg: Genex2,
      awards: [
      
        {
          title: "Services",
          description:
            "“The site is made in Word-press, the main ideas come from the owners, it is a great pleasure to work with such people.”",
        },
        {
          title: "Web design",
          description:
            "“Designing a website was a big task the main idea was visitor interaction and closer explanation of services.”",
        },
        {
          title: "Clients",
          description:
            "“The client showed great professionalism backed by strong arguments and visionary ideas Feel free and see for yourself !!! By visiting the site...”",
        },
      ],
    },
    {
      title: "Piks",
      mainImg: Piks,
      url: "/work/Piks",
      secondaryImg: Piks2,
      awards: [
        {
          title: "Services",
          description:
            "“The emphasis of the website was thrown on the image gallery, the client easily conveyed his idea and knew exactly in which direction he would go with the presentation.”",
        },
        {
          title: "Web design",
          description:
            "“The design of the website is greatly facilitated by the very idea of the client and his experience of visualization on the way to the final product..”",
        },
        {
          title: "Clients",
          description:
            "“Cooperation with the client was at a high level of ideas and requirements of the client. Feel free and see for yourself !!! By visiting the site…”",
        },
      ],
    },
    {
      title: "Music app",
      mainImg: musicapp,
      url: "/work/musicapp",
      secondaryImg: musicapp2,
      awards: [
        {
          title: "Services",
          description:
            "“The application was done in React, the use of hooks, effects and animation in the reaction gave the dynamics of the application and its true functionality.”",
        },
        {
          title: "Web design",
          description:
            "“The design of the application is simplified, and the team itself provides easier handling and visibility of the functions offered by the application..”",
        },
        {
          title: "For users",
          description:
            "“With the accompanying module package.json and the same version, you can hear the sounds of the application on your computer, feel free, and of course just do it!...”",
        },
      ],
    },
    {
      title: "Game app",
      mainImg: gameapp,
      url: "/work/gameapp",
      secondaryImg: gameapp2,
      awards: [
        {
          title: "Services",
          description:
            "“The application was done in React, the use of hooks, effects and animation in the reaction gave the dynamics of the application and its true functionality.”",
        },
        {
          title: "Web design",
          description:
            "“The design of the application is simplified, emphasis is placed on working with API addresses..”",
        },
        {
          title: "For users",
          description:
            "“With the accompanying module package.json and the same version, You can see popular games on the market right now, feel free, and of course just do it!...”",
        },
      ],
    },
    {
      title: "Game Javascript",
      mainImg: game,
      url: "/work/game",
      secondaryImg: game2,
      awards: [
        {
          title: "Services",
          description:
            "“Making a game in javascript gave me an interesting experience on how to learn the basics of functionality and provided strong support for further work and training.”",
        },
        {
          title: "Web design",
          description:
            "“The design of the game does not offer much, but it is enough for just functioning and fun.”",
        },
        {
          title: "For users",
          description:
            "“Have fun and try to remember your first victories and the happiness that accompanies every child on the path of growing up! In the end, we are all in the depths of ourselves actually big kids with different costumes!..”",
        },
      ],
    },
    {
      title: "HTML Form",
      mainImg: html1,
      url: "/work/html1",
      secondaryImg: html2,
      awards: [
        {
          title: "Services",
          description:
            "“One of the first projects in the process of learning HTML and CSS, setting elements and their functionality. The structure of the labels themselves and their positioning on the right example of the form”",
        },
        {
          title: "Web design",
          description:
            "“Designing the columns of the form itself and their rows gives a strong effect to the very reading and filling of the form and of course the understanding of the form.”",
        },
        {
          title: "For users",
          description:
            "“Use the form, look at the code and make your version of the form, insert your PHP code and make it functional...”",
        },
      ],
    },

    {
      title: "Cube Calculator",
      mainImg: cube,
      url: "/work/cube",
      secondaryImg: cube2,
      awards: [
        {
          title: "Services",
          description:
            "“ I use Javascript as the main resource in the project. The mathematical formula for calculating the surface area and volume of a cube.”",
        },
        {
          title: "Web design",
          description:
            "“Playing with the color spectrum in CSS I came up with a nuance that gives a different picture of the project itself.”",
        },
        {
          title: "For users",
          description:
            "“Mathematics as the main source of financial problems is not relevant here. The emphasis is on geometry and of course, a cube or a box may be ours lives, but still, no one has told us... No, it wasn't me”",
        },
      ],
    },
    {
      title: "ToDo List",
      mainImg: todo,
      url: "/work/todo",
      secondaryImg: todo2,
      awards: [
        {
          title: "Services",
          description:
            "“ I use Javascript as the main resource in the project. Todo list as a reminder, for things that are easy to forget and hard to do at the moment.”",
        },
        {
          title: "Web design",
          description:
            "“Playing with the color spectrum in CSS I came up with a nuance that gives a different picture of the project itself.”",
        },
        {
          title: "For users",
          description:
            "“Changing life habits and giving responsibility to ourselves and others, certainly helps us to react faster to daily obligations...Use the ToDO list every day!!!”",
        },
      ],
    },
    {
      title: "Beatmaker",
      mainImg: Beatmaker,
      url: "/work/Beatmaker",
      secondaryImg: Beatmaker2,
      awards: [
        {
          title: "Services",
          description:
            "“Beatmaker application that gives the user the freedom to create their own rhythm, Javascript as the main resource in the project.”",
        },
        {
          title: "Web design",
          description:
            "“Playing with the color spectrum in CSS to change equalizers and their color and shape transformation.”",
        },
        {
          title: "For users",
          description:
            "“Changing life and give yourself the freedom to chose, rhythm is one of the ways to start!!!”",
        },
      ],
    },
    {
      title: "Coloors",
      mainImg: Coloors,
      url: "/work/Coloors",
      secondaryImg: Coloors2,
      awards: [
        {
          title: "Services",
          description:
            "“Colors application that gives the user the choose your own color pallets, Javascript as the main resource in the project.”",
        },
        {
          title: "Web design",
          description:
            "“Playing with the color spectrum you can find the best solution and nuance for your site design.”",
        },
        {
          title: "For users",
          description:
            "“Lift up your creativity and give a new shape to your site, yes you have a choice!!!”",
        },
      ],
    },

  ];
};
