import React from "react";
//Animations
import { motion } from "framer-motion";
import { pageAnimation, titleAnim, photoAnim } from "../animation";
import styled from "styled-components";
import linkedin from "../img/linkedin.png";
import website from "../img/website.png";
import email from "../img/email.png";
import github from "../img/github.png";

const ContactMe = () => {
  return (
    <ContactStyle
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      style={{ background: "#fff" }}
    >
      <Title>
        <Hide>
        <motion.h2 variants={titleAnim}>&#60;contact&#62;</motion.h2>
        
          <motion.p variants={titleAnim}>
          I aim to attain an engaging internship position in the field of
             architecture solutions, full application development life cycle, 
             including investigation, analysis, design, build, test, 
             implement, maintenance and support...!</motion.p>
        </Hide>
      </Title>
      <div>
        <Hide>
          <Social variants={titleAnim}>
            <motion.img variants={photoAnim} src={website} alt="website" />
            <h4>&#123; Contact Me &#125;&#61; &#62;</h4>
            <p><a href="https://nonisha.tech/" target="_blank" rel="noopener noreferrer">&#40; Click to link ...&#41; &#59;</a></p>
          </Social>
        </Hide>
        <Hide>
          <Social variants={titleAnim}>
            <motion.img variants={photoAnim} src={email} alt="email" />
            <h4>&#123; Email &#125;</h4>
            <p>stanojevic.ninoslav@yahoo.com</p>
          </Social>
        </Hide>
        <Hide>
          <Social variants={titleAnim}>
            <motion.img variants={photoAnim} src={linkedin} alt="linkedin" />
            <h4>&#123; Linkedin &#125; &#61; &#62;</h4>
            <p><a href="https://www.linkedin.com/in/ninoslavstanojevic/" target="_blank" rel="noopener noreferrer">&#40; Click to link ...&#41; &#59;</a></p>
          </Social>
        </Hide>
        <Hide>
          <Social variants={titleAnim}>
            <motion.img variants={photoAnim} src={github} alt="github" />
            <h4>&#123; GitHub &#125; &#61; &#62;</h4>
            <p><a href="https://github.com/Noni2207" target="_blank" rel="noopener noreferrer">&#40; Click to link ...&#41; &#59;</a></p>
          </Social>
          <motion.h2 variants={titleAnim}>&#60;&#47;contact&#62;</motion.h2>
        </Hide>
      </div>
    </ContactStyle>
  );
};

const ContactStyle = styled(motion.div)`
  padding: 5rem 10rem;
  color: #353535;
  min-height: 90vh;
  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;
const Title = styled.div`
  margin-bottom: 4rem;
  color: black;
  @media (max-width: 1500px) {
    margin-top: 5rem;
  }
`;
const Hide = styled.div`
  overflow: hidden;
  h4{
    color: black;
    padding: 2rem 2rem 2rem 2rem;
  }
  h2 {
    padding: 1rem 0rem;
    font-weight: lighter;
  }
  p{
    color:#37594D;
    font-size: 1.5rem;
  }
   @media (max-width: 500px) {
  
    h2 {
      font-size: 2.5rem;
      font-weight: lighter;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 500;
    }
    a{  
      font-size: 1rem;  
    }
    p{
      font-size: 1rem;
    }
  }
  @media (max-width: 360px) {
    h4 {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
`;
const Social = styled(motion.div)`
  display: flex;
  align-items: center;
  h2{
    margin: 2rem;
  }
  a{  
    font-size: 20px;
    color: #37594D;
    text-decoration:none;  
  }
  p{
    color:#37594D;
  }
  @media (max-width: 500px) {
    a{  
      font-size: 1rem;  
    }
    img{
      width:2rem;
      haight:2rem;
    }
  }
`;

export default ContactMe;
