import React from "react";
//Global Style
import GlobalStyle from "./components/GlobalStyle";
//Import Pages
import AboutMe from "./pages/AboutMe";
import ContactMe from "./pages/ContactMe";
import OurWork from "./pages/OurWork";
import Nav from "./components/Nav";
import MovieDetail from "./pages/MovieDetail";
//Router
import { Switch, Route, useLocation } from "react-router-dom";
//Animation
import { AnimatePresence } from "framer-motion";
// Helmet 
import { Helmet } from "react-helmet";


function App() {
  const location = useLocation();

  return (
    <div className="App">
      <Helmet>
        <meta charSet= "UTF-8"/>
        <title>PoRtFoLiO NinOsLv</title> 
        <meta property= "og:title" content="Ninoslav.S PoRtFoLiO"/>
        <meta name="description" content= "This is a personal portfolio by Ninoslav Stanojevic"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
     
      <GlobalStyle />
      <Nav />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <AboutMe />
          </Route>
          <Route path="/work" exact>
            <OurWork />
          </Route>
          <Route path="/work/:id">
            <MovieDetail />
          </Route>
          <Route path="/contact">
            <ContactMe />
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;
