import React from "react";
import { Link } from "react-router-dom";
//Import Icons
import clock from "../img/clock.svg";
import diaphragm from "../img/diaphragm.svg";
import money from "../img/money.svg";
import teamwork from "../img/teamwork.svg";
import home2 from "../img/home2.png";
//Styles
import { About, Description, Image } from "../styles";
import styled from "styled-components";
import { scrollReveal } from "../animation";
import { useScroll } from "./useScroll";
//Framer Motion
import { motion } from "framer-motion";
import {  fade } from "../animation";

const ServicesSection = () => {
  const [element, controls] = useScroll();
  return (
    <Services
      variants={scrollReveal}
      animate={controls}
      initial="hidden"
      ref={element}
    >
      <Description>
        <h2>&#60;services&#62;</h2>
        <Cards>
          <Card>
            <div className="icon">
              <img alt="icon" src={clock} />
              <h3>Web Desing</h3>
            </div>
            <p>Website design may consider it to be good practice to conform to standards. 
              This is usually done via a description CSS...!</p>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={teamwork} />
              <h3>Front-end</h3>
            </div>
            <p>To solve your business problems, 
              I use a complete set of tools, from HTML5 to the latest JavaScript frameworks...!</p>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={diaphragm} />
              <h3>Back-end</h3>
            </div>
            <p>Inspired by challenges, problem-solving, and finding new architecture solutions.
               Including investigation, analysis with PHP...!</p>
          </Card>
          <Card>
            <div className="icon">
              <img alt="icon" src={money} />
              <h3>Teamwork</h3>
            </div>
            <p>I try to be a team player, a good coach and serve as a role model
               for everybody with the ability to handle different situations; i.e., 
               demonstrate the versatility...</p>
          </Card>
        </Cards>
      
        
        <h2>&#60;&#47;services&#62;</h2>
        </Description>
        <Image>
        <img alt="home2" src={home2} />
      </Image>
    </Services>
  );
};

const Services = styled(About)`
  h2 {
    padding-bottom: 5rem;
    margin: 0rem 25rem 0rem 0rem;
  }
  p {
    width: 70%;
    padding: 2rem 0rem 4rem 0rem;
  }
  h3 {
    font-size: 2.2rem;
    font-weight: 400;
  }
  a {
    color: white;
    text-decoration: none;
  }
  @media (max-width: 800px) {
  
    h2 {
      font-size: 3.5rem;
      margin: 0rem 90rem 0rem 0rem;
  }
  @media (max-width: 500px) {
  
    h2 {
      font-size: 3rem;
      font-weight: lighter;
    }
    h3 {
      font-size: 1rem;
      font-weight: 400;
    }
    p{  
        font-size: 1.2rem;  
      }
     
     
  }
`;
const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    justify-content: center;
  }

`;
const Card = styled.div`
  flex-basis: 20rem;
  .icon {
    display: flex;
    align-items: center;
    background: #1b1b1b;
    h3 {
      margin-left: 1rem
      background: transparent;
      color: #23d997;
      padding: 1rem;
    }
  }
    @media (max-width: 500px) {
      h3 {
        font-size: 1.8rem;
        font-weight: 400;
        margin: 1rem 1rem 0rem 0rem; 
      }
      p{  
          font-size: 1.2rem;
          margin: 0rem 1rem 1rem 3rem; 
        }
      img{
          width:2rem;
          haight:2rem;
          margin: 1rem 0rem 1rem 3rem;
        }  
       
    }
`;

export default ServicesSection;
