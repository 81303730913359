import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
//Images
import Baerumhub from "../img/Baerumhub.png";
import Piks from "../img/Piks.png";
import Genex from "../img/Genex.png";
import musicapp from "../img/musicapp.png";
import gameapp from "../img/gameapp.png";
import game from "../img/game.png";
import html1 from "../img/html1.png";
import cube from "../img/cube.png";
import todo from "../img/todo.png";
import Beatmaker from "../img/Beatmaker.png";
import Coloors from "../img/Coloors.png";

//Animations
import { motion } from "framer-motion";
import {
  sliderContainer,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim,
} from "../animation";
import { useScroll } from "../components/useScroll";
import ScrollTop from "../components/ScrollTop";

const OurWork = () => {
  const [element, controls] = useScroll();
  const [element2, controls2] = useScroll();
  const [element3, controls3] = useScroll();
  const [element4, controls4] = useScroll();
  const [element5, controls5] = useScroll();
  const [element6, controls6] = useScroll();
  const [element7, controls7] = useScroll();
  const [element8, controls8] = useScroll();
  const [element9, controls9] = useScroll();
  const [element10, controls10] = useScroll();
 

  return (
    <Work
      style={{ background: "#fff" }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <h1>&#60;work&#62;</h1>
      <motion.div variants={sliderContainer}>
        <Frame1 variants={slider}></Frame1>
        <Frame2 variants={slider}></Frame2>
        <Frame3 variants={slider}></Frame3>
        <Frame4 variants={slider}></Frame4>
      </motion.div>
      <Movie>
        <motion.h2 variants={fade}>Bærum hub ...</motion.h2> 
        <button><a href="https://www.bih.genex.no/" target="_blank" rel="noopener noreferrer">View site</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/Baerumhub">
          <Hide>
            <motion.img variants={photoAnim} src={Baerumhub} alt="Baerumhub" />
          </Hide>
        </Link>
      </Movie>

      <Movie ref={element} variants={fade} animate={controls} initial="hidden">
        <motion.h2>Piks ...</motion.h2> 
        <button><a href="https://piks.no/" target="_blank" rel="noopener noreferrer">View site</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/Piks">
          <img src={Piks} alt="Piks" />
        </Link>
      </Movie>
      <Movie ref={element2} variants={fade} animate={controls2} initial="hidden">
        <motion.h2>Genex ...</motion.h2>
        <button><a href="https://www.genex.no/" target="_blank" rel="noopener noreferrer">View site</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/Genex">
          <img src={Genex} alt="Genex" />
        </Link>
      </Movie>
      <Movie ref={element3} variants={fade} animate={controls3} initial="hidden">
        <motion.h2 variants={fade}>Music App ...</motion.h2> 
        <button><a href="https://nonimusicapp.netlify.app/" target="_blank" rel="noopener noreferrer">View project</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/musicapp">
          <Hide>
            <motion.img variants={photoAnim} src={musicapp} alt="musicapp" />
          </Hide>
        </Link>
      </Movie>
      <Movie ref={element4} variants={fade} animate={controls4} initial="hidden">
        <motion.h2 variants={fade}>Game App ...</motion.h2> 
        <button><a href="https://nonigameapp.netlify.app/" target="_blank" rel="noopener noreferrer">View project</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/gameapp">
          <Hide>
            <motion.img variants={photoAnim} src={gameapp} alt="gameapp" />
          </Hide>
        </Link>
      </Movie>
      <Movie ref={element5} variants={fade} animate={controls5} initial="hidden">
        <motion.h2 variants={fade}>Game Javascript ...</motion.h2> 
        <button><a href="https://nonigamejs.netlify.app/" target="_blank" rel="noopener noreferrer">View project</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/game">
          <Hide>
            <motion.img variants={photoAnim} src={game} alt="game" />
          </Hide>
        </Link>
      </Movie>
      <Movie ref={element6} variants={fade} animate={controls6} initial="hidden">
        <motion.h2 variants={fade}>HTML Form ...</motion.h2> 
        <button><a href="https://nonihtml.netlify.app" target="_blank" rel="noopener noreferrer">View project</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/html1">
          <Hide>
            <motion.img variants={photoAnim} src={html1} alt="html1" />
          </Hide>
        </Link>
      </Movie>
      <Movie ref={element7} variants={fade} animate={controls7} initial="hidden">
        <motion.h2 variants={fade}>Cube Calculator ...</motion.h2> 
        <button><a href="https://github.com/Noni2207/Cube" target="_blank" rel="noopener noreferrer">View project</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/cube">
          <Hide>
            <motion.img variants={photoAnim} src={cube} alt="cube" />
          </Hide>
        </Link>
      </Movie>
      <Movie ref={element8} variants={fade} animate={controls8} initial="hidden">
        <motion.h2 variants={fade}>ToDo List ...</motion.h2> 
        <button><a href="https://nonitodo.netlify.app" target="_blank" rel="noopener noreferrer">View project</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/todo">
          <Hide>
            <motion.img variants={photoAnim} src={todo} alt="todo" />
          </Hide>
        </Link>
      </Movie>
      <Movie ref={element9} variants={fade} animate={controls9} initial="hidden">
        <motion.h2 variants={fade}>Beatmaker-App ...</motion.h2> 
        <button><a href="https://nonibeat.netlify.app" target="_blank" rel="noopener noreferrer">View project</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/Beatmaker">
          <Hide>
            <motion.img variants={photoAnim} src={Beatmaker} alt="Beatmaker"/>
          </Hide>
        </Link>
      </Movie>
      <Movie ref={element10} variants={fade} animate={controls10} initial="hidden">
        <motion.h2 variants={fade}>Coloors-App ...</motion.h2> 
        <button><a href="https://nonicolors.netlify.app" target="_blank" rel="noopener noreferrer">View project</a></button>
        <p><span>&#60;&#33; &#45; &#45; </span>Read more by clicking on the photo <span>&#45; &#45; &#62;</span></p>
        <motion.div variants={lineAnim} className="line"></motion.div>
        <Link to="/work/Coloors">
          <Hide>
            <motion.img variants={photoAnim} src={Coloors} alt="Coloors"/>
          </Hide>
        </Link>
      </Movie>
      <ScrollTop />
      <h1>&#60;&#47;work&#62;</h1>
    </Work>
  );
};

const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 10rem;
  @media (max-width: 1300px) {
    padding: 2rem 2rem;
  }
  h1 {
    font-size: 4rem;
    font-weight: lighter;
  }
  h2 {
    font-size: 3rem;
    padding: 1rem 0rem;
    font-weight: 300;
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 2.5rem;
      font-weight: lighter;
    }
    h2 {
      font-size: 1rem;
      font-weight: 300;
    }
  }
  
  
`;
const Movie = styled(motion.div)`
  padding-bottom: 10rem;

  .line {
    height: 0.5rem;
    background: #23d997;
    margin-bottom: 3rem;
  }
  img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }
  a{  
    font-size: 20px;
    color: black;
    text-decoration:none; 
  }
  p{
    color: black;
  }
  span{ 
    color:#23d997
  }
  @media (max-width: 500px) {
  
  h2 {
    font-size: 1.5rem;
    font-weight: 400;
  }
  a{  
    font-size: 1rem;
    marin: 0rem 0rem 0rem 0rem;   
  }
  p{
    font-size: 1rem;
  }
}
`;
const Hide = styled.div`
  overflow: hidden;
`;

//Frame Animation
const Frame1 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 10%;
  width: 100%;
  height: 100vh;
  background: #DE354C;
  z-index: 2;
`;
const Frame2 = styled(Frame1)`
  background: #932432;
`;
const Frame3 = styled(Frame1)`
  background: #3C1874;
`;
const Frame4 = styled(Frame1)`
  background: #283747;
`;

export default OurWork;
