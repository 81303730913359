import React from "react";
import home1 from "../img/home1.png";
import { About, Description, Image, Hide } from "../styles";
import { Link } from "react-router-dom";
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import Wave from "./Wave";

const AboutSection = () => {
  return (
    <About>
      <Description>
      
        <motion.div>
          <Hide>
            <motion.h2 variants={titleAnim}>I work to make</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              your <span>dreams</span> come
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>true.</motion.h2>
          </Hide>
        </motion.div>
        <motion.p variants={fade}>
        Hello, my name is
         Ninoslav Stanojevic
        And I'm a Web Developer...!
        </motion.p>
        <Link to="/contact"><motion.button variants={fade}>Contact Me</motion.button></Link>
      </Description>
      <Image>
        <motion.img variants={photoAnim} src={home1} alt="home1" />
      </Image>
    </About>
  );
};

//Styled Components

export default AboutSection;
